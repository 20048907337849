<template>
  <mobile-screen :header="true" screen-class="icon-app1 count-screen gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="count-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: helpLinkName
        }"
      >
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("count", "export", "export") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section pill">
      <li
        class="clebex-item-section-item slide-icon-border full-right-underline"
      >
        <button
          @click="getExport('current')"
          class="clebex-item-content-wrapper"
        >
          <dl class="clebex-item-dl">
            <dd class="clebex-item-dd">
              <div>
                {{ displayLabelName("count", "export", "current") }}
              </div>
            </dd>
          </dl>
        </button>
      </li>
      <li
        v-if="backLinkName !== 'r_count-technical-list'"
        class="clebex-item-section-item slide-icon-border full-right-underline"
      >
        <button
          @click="getExport('historic')"
          class="clebex-item-content-wrapper"
        >
          <dl class="clebex-item-dl">
            <dd class="clebex-item-dd">
              <div>
                {{ displayLabelName("count", "export", "historic") }}
              </div>
            </dd>
          </dl>
        </button>
      </li>
      <li
        v-if="showPipExport"
        class="clebex-item-section-item slide-icon-border full-right-underline"
      >
        <button
          @click="getExport('pipiot-historic')"
          class="clebex-item-content-wrapper"
        >
          <dl class="clebex-item-dl">
            <dd class="clebex-item-dd">
              <div>
                {{ displayLabelName("count", "export", "historic-barrier") }}
              </div>
            </dd>
          </dl>
        </button>
      </li>
      <li
        class="clebex-item-section-item slide-icon-border full-right-underline"
      >
        <button
          @click="getExport('pipiot-barrier')"
          class="clebex-item-content-wrapper"
        >
          <dl class="clebex-item-dl">
            <dd class="clebex-item-dd">
              <div>
                {{ displayLabelName("count", "export", "pipiot-barrier") }}
              </div>
            </dd>
          </dl>
        </button>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  mixins: [helpOnlineMixin],
  name: "CountExport",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      helpSlug: "count-export",
      showPipExport: false
    };
  },
  created() {
    window.addEventListener("keydown", e => {
      if (e.ctrlKey && e.keyCode == 80) {
        this.showPipExport = !this.showPipExport;
      }
    });
  },
  methods: {
    ...mapActions("count", ["getExport"])
  },
  props: {
    backLinkName: {
      type: String
    },
    helpLinkName: {
      type: String
    }
  }
};
</script>
